// Import our CSS
import styles from '../css/app.pcss';

// Import jquery and make it available


// Import basicfont
import quicksand from '../webfonts/quicksand/Quicksand-Regular.ttf';

// Import fontawesome svgs
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faLoveseat } from "@fortawesome/pro-solid-svg-icons/faLoveseat";
import { faKey } from "@fortawesome/pro-solid-svg-icons/faKey";
import { faStethoscope } from "@fortawesome/pro-solid-svg-icons/faStethoscope";
import { faUmbrella } from "@fortawesome/pro-solid-svg-icons/faUmbrella";
import { faHandshakeAlt } from "@fortawesome/pro-solid-svg-icons/faHandshakeAlt";
import { faDesktop } from "@fortawesome/pro-solid-svg-icons/faDesktop";
import { faUserShield } from "@fortawesome/pro-solid-svg-icons/faUserShield";
import { faBed } from "@fortawesome/pro-solid-svg-icons/faBed";
import { faUserMd } from "@fortawesome/pro-solid-svg-icons/faUserMd";
import { faUserCrown } from "@fortawesome/pro-solid-svg-icons/faUserCrown";
import { faDoorClosed } from "@fortawesome/pro-solid-svg-icons/faDoorClosed";
import { faUserInjured } from "@fortawesome/pro-solid-svg-icons/faUserInjured";
import { faGuitarElectric } from "@fortawesome/pro-solid-svg-icons/faGuitarElectric";
import { faHomeHeart } from "@fortawesome/pro-solid-svg-icons/faHomeHeart";
import { faGlobeEurope } from "@fortawesome/pro-solid-svg-icons/faGlobeEurope";
import { faDogLeashed } from "@fortawesome/pro-solid-svg-icons/faDogLeashed";
import { faUnicorn } from "@fortawesome/pro-solid-svg-icons/faUnicorn";
import { faTooth } from "@fortawesome/pro-solid-svg-icons/faTooth";
import { faBicycle } from "@fortawesome/pro-solid-svg-icons/faBicycle";
import { faChevronCircleRight } from "@fortawesome/pro-solid-svg-icons/faChevronCircleRight";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons/faChevronRight";
import { faComments } from "@fortawesome/pro-solid-svg-icons/faComments";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faPhoneAlt } from "@fortawesome/pro-solid-svg-icons/faPhoneAlt";
import { faEye } from "@fortawesome/pro-solid-svg-icons/faEye";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { faFragile } from "@fortawesome/pro-solid-svg-icons/faFragile";
import { faEuroSign } from "@fortawesome/pro-solid-svg-icons/faEuroSign";
import { faCouch } from "@fortawesome/pro-solid-svg-icons/faCouch";
import { faAngleDoubleRight } from "@fortawesome/pro-solid-svg-icons/faAngleDoubleRight";
import { faBuilding } from "@fortawesome/pro-solid-svg-icons/faBuilding";
import { faHouseReturn } from "@fortawesome/pro-solid-svg-icons/faHouseReturn";
import { faUserNurse } from "@fortawesome/pro-solid-svg-icons/faUserNurse";
import { faDesktopAlt } from "@fortawesome/pro-solid-svg-icons/faDesktopAlt";
import { faUserSlash } from "@fortawesome/pro-solid-svg-icons/faUserSlash";
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import { faUsers } from "@fortawesome/pro-solid-svg-icons/faUsers";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import { faChevronCircleDown } from "@fortawesome/pro-solid-svg-icons/faChevronCircleDown";
import { faChevronCircleUp } from "@fortawesome/pro-solid-svg-icons/faChevronCircleUp";
import { faSignature } from "@fortawesome/pro-solid-svg-icons/faSignature";
import { faCalendarDay } from "@fortawesome/pro-solid-svg-icons/faCalendarDay";
import { faPause } from "@fortawesome/pro-solid-svg-icons/faPause";
import { faCalendarAlt } from "@fortawesome/pro-solid-svg-icons/faCalendarAlt";
import { faCalendarTimes } from "@fortawesome/pro-solid-svg-icons/faCalendarTimes";
import { faHistory } from "@fortawesome/pro-solid-svg-icons/faHistory";
import { faArrows } from "@fortawesome/pro-solid-svg-icons/faArrows";
import { faSlidersH } from "@fortawesome/pro-solid-svg-icons/faSlidersH";
import { faPuzzlePiece } from "@fortawesome/pro-solid-svg-icons/faPuzzlePiece";
import { faClock } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faRibbon } from "@fortawesome/pro-solid-svg-icons/faRibbon";
import { faPills } from "@fortawesome/pro-solid-svg-icons/faPills";
import { faPlayCircle } from "@fortawesome/pro-solid-svg-icons/faPlayCircle";
import { faSitemap } from "@fortawesome/pro-solid-svg-icons/faSitemap";
import { faStars } from "@fortawesome/pro-solid-svg-icons/faStars";
import { faCodeBranch } from "@fortawesome/pro-solid-svg-icons/faCodeBranch";
import { faBusinessTime } from "@fortawesome/pro-solid-svg-icons/faBusinessTime";
import { faBriefcase } from "@fortawesome/pro-solid-svg-icons/faBriefcase";
import { faGavel } from "@fortawesome/pro-solid-svg-icons/faGavel";
import { faCar } from "@fortawesome/pro-solid-svg-icons/faCar";
import { faGlasses } from "@fortawesome/pro-solid-svg-icons/faGlasses";
import { faHome } from "@fortawesome/pro-solid-svg-icons/faHome";
import { faChairOffice } from "@fortawesome/pro-solid-svg-icons/faChairOffice";
import { faExchange } from "@fortawesome/pro-solid-svg-icons/faExchange";
import { faFileAlt } from "@fortawesome/pro-solid-svg-icons/faFileAlt";
import { faPhoneLaptop } from "@fortawesome/pro-solid-svg-icons/faPhoneLaptop";
import { faLongArrowLeft } from "@fortawesome/pro-solid-svg-icons/faLongArrowLeft";
import { faLongArrowRight } from "@fortawesome/pro-solid-svg-icons/faLongArrowRight";
import { faLaptop } from "@fortawesome/pro-solid-svg-icons/faLaptop";
import { faCarCrash } from "@fortawesome/pro-solid-svg-icons/faCarCrash";
import { faHandPaper } from "@fortawesome/pro-solid-svg-icons/faHandPaper";
import { faChartLine } from "@fortawesome/pro-solid-svg-icons/faChartLine";
import { faCalendarStar } from "@fortawesome/pro-solid-svg-icons/faCalendarStar";
import { faDrawCircle } from "@fortawesome/pro-solid-svg-icons/faDrawCircle";
import { faTombstone } from "@fortawesome/pro-solid-svg-icons/faTombstone";
import { faBaby } from "@fortawesome/pro-solid-svg-icons/faBaby";
import { faHeartbeat } from "@fortawesome/pro-solid-svg-icons/faHeartbeat";
import { faHeadSideBrain } from "@fortawesome/pro-solid-svg-icons/faHeadSideBrain";
import { faAbacus } from "@fortawesome/pro-solid-svg-icons/faAbacus";
import { faLock } from "@fortawesome/pro-solid-svg-icons/faLock";
import { faWeight } from "@fortawesome/pro-solid-svg-icons/faWeight";
import { faScalpel } from "@fortawesome/pro-solid-svg-icons/faScalpel";
import { faHelicopter } from "@fortawesome/pro-solid-svg-icons/faHelicopter";
import { faCalculatorAlt } from "@fortawesome/pro-solid-svg-icons/faCalculatorAlt";
import { faCalculator } from "@fortawesome/pro-solid-svg-icons/faCalculator";
import { faCamera } from "@fortawesome/pro-solid-svg-icons/faCamera";
import { faUserFriends } from "@fortawesome/pro-solid-svg-icons/faUserFriends";
import { faFaucetDrip } from "@fortawesome/pro-solid-svg-icons/faFaucetDrip";
import { faBullhorn } from "@fortawesome/pro-solid-svg-icons/faBullhorn";
import { faFrown } from "@fortawesome/pro-solid-svg-icons/faFrown";
import { faMeh } from "@fortawesome/pro-solid-svg-icons/faMeh";
import { faSmile } from "@fortawesome/pro-solid-svg-icons/faSmile";
import { faExternalLink } from "@fortawesome/pro-solid-svg-icons/faExternalLink";
import { faPaperPlane } from "@fortawesome/pro-solid-svg-icons/faPaperPlane";
import { faVenusMars } from "@fortawesome/pro-solid-svg-icons/faVenusMars";
import { faDisease } from "@fortawesome/pro-solid-svg-icons/faDisease";
import { faStar } from "@fortawesome/pro-solid-svg-icons/faStar";
import { faStarHalf } from "@fortawesome/pro-solid-svg-icons/faStarHalf";
import { faPersonCarry } from "@fortawesome/pro-solid-svg-icons/faPersonCarry";
import { faHeartRate } from "@fortawesome/pro-solid-svg-icons/faHeartRate";
import { faHospital } from "@fortawesome/pro-solid-svg-icons/faHospital";
import { faChild } from "@fortawesome/pro-solid-svg-icons/faChild";
import { faSparkles } from "@fortawesome/pro-solid-svg-icons/faSparkles";
import { faBabyCarriage } from "@fortawesome/pro-solid-svg-icons/faBabyCarriage";
import { faHandsHelping } from "@fortawesome/pro-solid-svg-icons/faHandsHelping";
import { faSackDollar } from "@fortawesome/pro-solid-svg-icons/faSackDollar";
import { faHouseDamage } from "@fortawesome/pro-solid-svg-icons/faHouseDamage";
import { faHeart } from "@fortawesome/pro-solid-svg-icons/faHeart";
import { faThumbsUp } from "@fortawesome/pro-solid-svg-icons/faThumbsUp";
import { faThumbsDown } from "@fortawesome/pro-solid-svg-icons/faThumbsDown";
import { faVirus } from "@fortawesome/pro-solid-svg-icons/faVirus";
import { faAnalytics } from "@fortawesome/pro-solid-svg-icons/faAnalytics";
import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { faUserHeadset } from "@fortawesome/pro-solid-svg-icons/faUserHeadset";
import { faMegaphone } from "@fortawesome/pro-solid-svg-icons/faMegaphone";
import { faTasks } from "@fortawesome/pro-solid-svg-icons/faTasks";
import { faFingerprint } from "@fortawesome/pro-solid-svg-icons/faFingerprint";
import { faHandHoldingMedical } from "@fortawesome/pro-solid-svg-icons/faHandHoldingMedical";
import { faHourglass } from "@fortawesome/pro-solid-svg-icons/faHourglass";
import { faBirthdayCake } from "@fortawesome/pro-solid-svg-icons/faBirthdayCake";
import { faAnchor } from "@fortawesome/pro-solid-svg-icons/faAnchor";
import { faRabbitFast } from "@fortawesome/pro-solid-svg-icons/faRabbitFast";
import { faBullseyeArrow } from "@fortawesome/pro-solid-svg-icons/faBullseyeArrow";
import { faTurntable } from "@fortawesome/pro-solid-svg-icons/faTurntable";
import { faFileDownload } from "@fortawesome/pro-solid-svg-icons/faFileDownload";
import { faPlug } from "@fortawesome/pro-solid-svg-icons/faPlug";
import { faQuestion } from "@fortawesome/pro-solid-svg-icons/faQuestion";
import { faSalad } from "@fortawesome/pro-solid-svg-icons/faSalad";
import { faLaughBeam } from "@fortawesome/pro-solid-svg-icons/faLaughBeam";
import { faArrowsH } from "@fortawesome/pro-solid-svg-icons/faArrowsH";
import { faSmoking } from "@fortawesome/pro-solid-svg-icons/faSmoking";
import { faSmokingBan } from "@fortawesome/pro-solid-svg-icons/faSmokingBan";
import { faGraduationCap } from "@fortawesome/pro-solid-svg-icons/faGraduationCap";
import { faSyringe } from "@fortawesome/pro-solid-svg-icons/faSyringe";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons/faCheckSquare";

// Duotone Icons
import { faQuidditch } from "@fortawesome/pro-duotone-svg-icons/faQuidditch";
import { faHandHeart } from "@fortawesome/pro-duotone-svg-icons/faHandHeart";
import { faToothbrush } from "@fortawesome/pro-duotone-svg-icons/faToothbrush";
import { faUndo } from "@fortawesome/pro-duotone-svg-icons/faUndo";
import { faBackpack } from "@fortawesome/pro-duotone-svg-icons/faBackpack";
import { faCrow } from "@fortawesome/pro-duotone-svg-icons/faCrow";
import { faFistRaised } from "@fortawesome/pro-duotone-svg-icons/faFistRaised";
import { faMindShare } from "@fortawesome/pro-duotone-svg-icons/faMindShare";
import { faExpandArrowsAlt } from "@fortawesome/pro-duotone-svg-icons/faExpandArrowsAlt";
import { faWalker } from "@fortawesome/pro-duotone-svg-icons/faWalker";
import { faSmilePlus } from "@fortawesome/pro-duotone-svg-icons/faSmilePlus";
import { faCommentSmile } from "@fortawesome/pro-solid-svg-icons/faCommentSmile";

// Light Icons
import { faAngleDown } from "@fortawesome/pro-light-svg-icons/faAngleDown";
import { faAngleUp } from "@fortawesome/pro-light-svg-icons/faAngleUp";
import { faSearch } from "@fortawesome/pro-light-svg-icons/faSearch";
import { faBars } from "@fortawesome/pro-light-svg-icons/faBars";
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus";
import { faAngleRight } from "@fortawesome/pro-light-svg-icons/faAngleRight";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons/faChevronDown";

// Brand Icons
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons/faWhatsapp";

library.add(faLoveseat);
library.add(faKey);
library.add(faStethoscope);
library.add(faUmbrella);
library.add(faHandshakeAlt);
library.add(faDesktop);
library.add(faUserShield);
library.add(faBed);
library.add(faUserMd);
library.add(faUserCrown);
library.add(faDoorClosed);
library.add(faUserInjured);
library.add(faHomeHeart);
library.add(faGuitarElectric);
library.add(faGlobeEurope);
library.add(faDogLeashed);
library.add(faTooth);
library.add(faBicycle);
library.add(faChevronCircleRight);
library.add(faChevronRight);
library.add(faUnicorn);
library.add(faComments);
library.add(faEnvelope);
library.add(faPhoneAlt);
library.add(faEye);
library.add(faCheckCircle);
library.add(faFileAlt);
library.add(faFragile);
library.add(faEuroSign);
library.add(faCouch);
library.add(faAngleDoubleRight);
library.add(faBuilding);
library.add(faHouseReturn);
library.add(faUserNurse);
library.add(faDesktopAlt);
library.add(faUserSlash);
library.add(faUser);
library.add(faUsers);
library.add(faCheck);
library.add(faTimes);
library.add(faInstagram);
library.add(faFacebookF);
library.add(faWhatsapp);
library.add(faInfoCircle);
library.add(faChevronCircleDown);
library.add(faChevronCircleUp);
library.add(faSignature);
library.add(faCalendarDay);
library.add(faPause);
library.add(faCalendarAlt);
library.add(faCalendarTimes);
library.add(faHistory);
library.add(faAngleUp);
library.add(faAngleDown);
library.add(faFileDownload);
library.add(faArrows);
library.add(faSlidersH);
library.add(faPuzzlePiece);
library.add(faClock);
library.add(faRibbon);
library.add(faPills);
library.add(faPlayCircle);
library.add(faSmoking);
library.add(faSmokingBan);
library.add(faGraduationCap);
library.add(faArrowsH);
library.add(faSitemap);
library.add(faStars);
library.add(faCodeBranch);
library.add(faSyringe);
library.add(faBusinessTime);
library.add(faCheckSquare);
library.add(faBriefcase);
library.add(faGavel);
library.add(faCar);
library.add(faGlasses);
library.add(faHome);
library.add(faChairOffice);
library.add(faExchange);
library.add(faPhoneLaptop);
library.add(faLongArrowLeft);
library.add(faLongArrowRight);
library.add(faAngleRight);
library.add(faLaptop);
library.add(faCarCrash);
library.add(faHandPaper);
library.add(faChartLine);
library.add(faCalendarStar);
library.add(faDrawCircle);
library.add(faTombstone);
library.add(faBaby);
library.add(faHeartbeat);
library.add(faHeadSideBrain);
library.add(faAbacus);
library.add(faLock);
library.add(faWeight);
library.add(faHelicopter);
library.add(faScalpel);
library.add(faCalculatorAlt);
library.add(faCalculator);
library.add(faCamera);
library.add(faUserFriends);
library.add(faFaucetDrip);
library.add(faBullhorn);
library.add(faFrown);
library.add(faMeh);
library.add(faSmile);
library.add(faExternalLink);
library.add(faPaperPlane);
library.add(faSearch);
library.add(faBars);
library.add(faVenusMars);
library.add(faDisease);
library.add(faStar);
library.add(faStarHalf);
library.add(faPersonCarry);
library.add(faHeartRate);
library.add(faHospital);
library.add(faChild);
library.add(faSparkles);
library.add(faBabyCarriage);
library.add(faHandsHelping);
library.add(faSackDollar);
library.add(faHouseDamage);
library.add(faHeart);
library.add(faThumbsUp);
library.add(faThumbsDown);
library.add(faCircle);
library.add(faUserHeadset);
library.add(faMegaphone);
library.add(faTasks);
library.add(faFingerprint);
library.add(faHandHoldingMedical);
library.add(faHourglass);
library.add(faBirthdayCake);
library.add(faAnchor);
library.add(faPlus);
library.add(faRabbitFast);
library.add(faBullseyeArrow);
library.add(faTurntable);
library.add(faVirus);
library.add(faAnalytics);
library.add(faQuidditch);
library.add(faPlug);
library.add(faQuestion);
library.add(faHandHeart);
library.add(faToothbrush);
library.add(faUndo);
library.add(faBackpack);
library.add(faCrow);
library.add(faFistRaised);
library.add(faMindShare);
library.add(faExpandArrowsAlt);
library.add(faWalker);
library.add(faSmilePlus);
library.add(faSalad);
library.add(faLaughBeam);
library.add(faChevronDown);
library.add(faCommentSmile);

dom.watch();

window.FontAwesomeConfig = {
    searchPseudoElements: true
}

// Import lazysizes for lazy loading images
import 'lazysizes';
if(document.querySelector('.lazyload')){ //if you load the script at the bottom you don't need to test this, just call init
    lazySizes.init();
}

// Swiper
import Swiper from 'swiper';

import 'mmenu-js/dist/addons/iconbar/mmenu.iconbar'
import Mmenu from 'mmenu-js';

// App main
const main = async () => {
    // Async load the vue module
    const { default: Vue } = await import('vue');

    // Create our vue instance
    const vm = new Vue({
        el: "#app",
        components: {
            'producthighlights': () => import( '../vue/ProductHighlights.vue'),
            'productnavigation': () => import( '../vue/ProductNavigation.vue'),
            'magazinnavigation': () => import( '../vue/MagazinNavigation.vue'),

            // Altersvorsorge
            'altersvorsorgebasisrente': () => import( '../vue/BasisrenteSlider.vue'),
            'altersvorsorgeprivatrente': () => import( '../vue/PrivatrenteSlider.vue'),

            // Product BHV
            'productbhvpricemodal': () => import( '../vue/ProductBHVPriceModal.vue'),

            // Product KT
            'productktpricemodal': () => import( '../vue/ProductKTPriceModal.vue'),

            // Product KT
            'productppricemodal': () => import( '../vue/ProductPPriceModal.vue'),

            // Product RS
            'productrspricemodal': () => import( '../vue/ProductRSPriceModal.vue'),

            // Product RL
            'todesfallabsicherung': () => import( '../vue/Todesfallabsicherung.vue'),

            // Product PI
            'productpipricemodal': () => import( '../vue/ProductPIPriceModal.vue'),

            // Charts
            'buchartkrankheiten': () => import( '../vue/ChartBUKrankheiten.vue'),
            'buchartleistet': () => import( '../vue/ChartBULeistet.vue'),
            'buchartunterschied': () => import( '../vue/ChartBUUnterschiede.vue'),
            'bedarf': () => import( '../vue/Versorgung.vue'),

            //Mein Bedarf
            'bedarfperson': () => import( '../vue/BedarfPerson.vue'),
            'bedarfberuf': () => import( '../vue/BedarfBeruf.vue'),
            'bedarfpraxis': () => import( '../vue/BedarfPraxis.vue'),

            //Schaden
            'damage': () => import( '../vue/Damage.vue'),

            // Functions
            'collapse': () => import( '../vue/Collapse.vue'),
            'faqcollapse': () => import( '../vue/FaqCollapse.vue'),
            'faq': () => import( '../vue/Faq.vue'),
            'faqpp': () => import( '../vue/FaqPP.vue'),
            'tooltip': () => import( '../vue/Tooltip.vue'),

            //Other
            'erstinformation': () => import( '../vue/Erstinformation.vue'),
            'erstinformationpp': () => import( '../vue/ErstinformationPP.vue'),

            // Geo Search
            'ausbildungsinstitute': () => import( '../vue/Ausbildungsinstitute.vue'),


            // Wünsche und Bedürfnisse im Checkout
            'wunschzz': () => import( '../vue/WunschZZ.vue'),
            'wunschbhv': () => import( '../vue/WunschBHV.vue'),
            'wunschgkvz': () => import( '../vue/WunschGKVZ.vue'),
            'wunschrad': () => import( '../vue/WunschRad.vue'),
        },
    });

    const search = new Vue({
        el: "#search",
        components: {
            'search': () => import( '../vue/Search.vue'),
        }
    });

    return vm && search;
};

// Execute async function
main().then( (vm) => {
}).then( (search) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}

// History back function for algolia instantsearch entries
window.goBack = () => {
    window.history.back();
};

// Add Class to body, when page is loaded
window.onload = function() {
    document.getElementById('body').classList.add("body--ready");
};

// MMenu Setup
document.addEventListener(
    "DOMContentLoaded", () => {
        new Mmenu( "#mobilenav", {
            extensions 	: [ "shadow-panels", "fx-menu-slide", "fx-listitems-slide", "border-none", "theme-black", "fullscreen", "position-right", "pagedim" ],
            navbars     : [{
                height  : 3,
                content : [
                    "prev",
                    '<p class="text-white font-bold uppercase">Navigation</p>',
                    "close"
                ]
            }],
            iconbar: {
                "use": true,
                "top": [
                    "<a href='/'><i class='fas fa-home'></i></a>"
                ],
                "bottom": [
                    "<a href='mailto:post@pluswert.de?subject=Hallo%20Kessel%20Festival'><i class='fas fa-envelope'></i></a>",
                    "<a href='https://www.facebook.com/pluswert'><i class='fab fa-facebook-f'></i></a>",
                    "<a href='https://www.instagram.com/pluswert/'><i class='fab fa-instagram'></i></a>"
                ]
            }
        }, {
            offCanvas: {
                pageSelector: ".wrapper"
            }
    }
);

const swiper = new Swiper('.slider', {
    speed: 4000,
    autoplay: {
        delay: 4000,
    },
    fadeEffect: {
        crossFade: true
    },
    loop: true,
    effect: "fade"
});

// Form Information Collapse
const col = document.getElementsByClassName("form__infobutton");
let i;

for (i = 0; i < col.length; i++) {
    col[i].addEventListener("click", function() {
        this.classList.toggle("active");
        const content = this.nextElementSibling;
        content.classList.toggle("active");
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
        } else {
            content.style.maxHeight = content.scrollHeight + "px";
        }
    });
}})